export interface Product {
  id: string;
  name: string;
  description: string;
  price: number;
  isActive: boolean;
  stripeProductId?: string;
  stripePriceId?: string;
  createdAt: string;
  updatedAt: string;
  type: ProductType;
  currency: Currency;
  listingsTokens: number;
  eventClickTokens: number;
}

export interface CreateProductRequest {
  name: string;
  description: string;
  price: number;
  isActive: boolean;
  type: ProductType;
  currency: Currency;
  listingsTokens: number;
  eventClickTokens: number;
}

export interface UpdateProductRequest {
  id: string;
  name: string;
  description: string;
  price: number;
  isActive: boolean;
  type: ProductType;
  currency: Currency;
  listingsTokens: number;
  eventClickTokens: number;
}

export enum ProductType {
  Subscription = 100,
  OneTime = 200,
}

export enum Currency {
  USD,
  EUR,
  NOK,
  SEK,
  DKK,
}
