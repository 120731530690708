import { Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import './App.css';
import { ThemeProvider } from './context/ThemeContext';
import { CheckoutConfirmation } from './pages/CompanyPage/CompanyDetails/components/CheckoutConfirmation.tsx';

// Lazy load components
const ProtectedRoute = lazy(() => import('./components/ProtectedRoute'));
const NotFound = lazy(() => import('./pages/ErrorPages/NotFound'));
const Forbidden = lazy(() => import('./pages/ErrorPages/Forbidden'));
const Error = lazy(() => import('./pages/ErrorPages/Error'));

const Layout = lazy(() => import('./components/layout'));
const HomePage = lazy(() => import('./pages/HomePage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const AdminCenter = lazy(() => import('./pages/AdminCenter'));
const AdminCategories = lazy(
  () => import('./pages/AdminCenter/AdminCategories')
);
const RegisterPage = lazy(() => import('./pages/RegisterPage'));
const CompanyPage = lazy(() => import('./pages/CompanyPage'));
const CompanyDetails = lazy(() => import('./pages/CompanyPage/CompanyDetails'));
const AdminCategoryServices = lazy(
  () => import('./pages/AdminCenter/AdminCategoryServices')
);
const AdminSubCategories = lazy(
  () => import('./pages/AdminCenter/AdminSubCategories')
);
const AdminSubCategoryServices = lazy(
  () => import('./pages/AdminCenter/AdminSubCategoryServices')
);
const ProductsPage = lazy(() => import('./pages/Products/ProductsPage'));
const CompanyListingPage = lazy(
  () => import('./pages/CompanyPage/CompanyListings/CompanyListingPage')
);

const ListingsPage = lazy(() => import('./pages/ListingsPage'));
const ListingItemPage = lazy(() => import('./pages/ListingItemPage'));

// Loading component
const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 dark:border-white"></div>
  </div>
);

function App() {
  return (
    <ThemeProvider>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {/* Layout Route */}
          <Route path="/" element={<Layout />}>
            {/* Index Route */}
            <Route
              index
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <HomePage />
                </Suspense>
              }
            />

            <Route
              path={'/listings/*'}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ListingsPage />
                </Suspense>
              }
            />
            <Route
              path={'/listing/:id'}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ListingItemPage />
                </Suspense>
              }
            />

            {/* Auth Routes */}
            <Route
              path="/login"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <LoginPage />
                </Suspense>
              }
            />
            <Route
              path="/register"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <RegisterPage />
                </Suspense>
              }
            />

            {/* Protected Company Routes */}
            <Route
              path="/companies"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ProtectedRoute requireAuth>
                    <CompanyPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path="/company/*"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ProtectedRoute requireAuth>
                    <CompanyDetails />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path="/company/listing/*"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ProtectedRoute requireAuth>
                    <CompanyListingPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path="/company/order-complete/*"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ProtectedRoute requireAuth>
                    <CheckoutConfirmation />
                  </ProtectedRoute>
                </Suspense>
              }
            />

            {/* Protected Admin Routes */}
            <Route
              path="/admin"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ProtectedRoute requireAuth requireAdmin>
                    <AdminCenter />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path="/admin/categories"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ProtectedRoute requireAuth requireAdmin>
                    <AdminCategories />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path="/admin/subcategories"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ProtectedRoute requireAuth requireAdmin>
                    <AdminSubCategories />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path="/admin/category-services"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ProtectedRoute requireAuth requireAdmin>
                    <AdminCategoryServices />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path="/admin/subcategory-services"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ProtectedRoute requireAuth requireAdmin>
                    <AdminSubCategoryServices />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path="/admin/products"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ProtectedRoute requireAuth requireAdmin>
                    <ProductsPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>

          {/* Error Routes */}
          <Route
            path="/403"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Forbidden />
              </Suspense>
            }
          />
          <Route
            path="/500"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Error />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <NotFound />
              </Suspense>
            }
          />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
