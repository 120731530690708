// authSlice.ts

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utilis/axios';
import { AuthResponse } from '../../types/states/AuthState';
import { apiUrl } from '../../main.tsx';

const initialState: AuthResponse = {
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken'),
  user: JSON.parse(localStorage.getItem('user') || 'null'),
  status: 'idle',
  error: null,
  message: null,
  expiresIn: null,
  success: false,
};

export const login = createAsyncThunk(
  'auth/login',
  async (credentials: { email: string; password: string }) => {
    const response = await axiosInstance.post('/auth/login', credentials);
    return response.data;
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async (userData: {
    email: string;
    password: string;
    confirmPassword: string;
  }) => {
    const response = await axiosInstance.post(
      apiUrl + '/auth/register',
      userData
    );
    return response.data;
  }
);

export const confirmEmail = createAsyncThunk(
  'auth/confirmEmail',
  async (data: { token: string; userId: string }) => {
    const response = await axiosInstance.get(
      apiUrl + `/auth/confirm-email?token=${data.token}&userId=${data.userId}`
    );
    return response.data;
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (email: string) => {
    const response = await axiosInstance.post(
      apiUrl + '/auth/forgot-password',
      {
        email,
      }
    );
    return response.data;
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data: {
    token: string;
    userId: string;
    password: string;
    confirmPassword: string;
  }) => {
    const response = await axiosInstance.post(
      apiUrl + `/auth/reset-password?token=${data.token}&userId=${data.userId}`,
      {
        password: data.password,
        confirmPassword: data.confirmPassword,
      }
    );
    return response.data;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthState: (state) => {
      state.status = 'idle';
      state.error = null;
      state.message = null;
      state.success = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.user = action.payload.user;
        state.expiresIn = action.payload.expiresIn;
        localStorage.setItem('accessToken', action.payload.accessToken);
        localStorage.setItem('refreshToken', action.payload.refreshToken);
        localStorage.setItem('user', JSON.stringify(action.payload.user));
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Login failed';
      })
      .addCase(register.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(register.fulfilled, (state) => {
        state.status = 'succeeded';
        state.success = true;
      })
      .addCase(register.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Registration failed';
      })
      .addCase(confirmEmail.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(confirmEmail.fulfilled, (state) => {
        state.status = 'succeeded';
        state.success = true;
      })
      .addCase(confirmEmail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Email confirmation failed';
      })
      .addCase(forgotPassword.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.status = 'succeeded';
        state.success = true;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Password reset request failed';
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.status = 'succeeded';
        state.success = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Password reset failed';
      });
  },
});

export const { clearAuthState } = authSlice.actions;

export const selectAuth = (state: { auth: AuthResponse }) => state.auth;

export default authSlice.reducer;
