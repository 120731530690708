// src/store/slices/categorySlice.ts

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axiosInstance from '../../utilis/axios';
import { CategoriesState } from '../../types/states/CategoryState';
import {
  CreateCategoryRequest,
  UpdateCategoryRequest,
} from '../../types/requests/CategoryRequests';
import { apiUrl } from '../../main';

const initialState: CategoriesState = {
  categories: [],
  status: 'idle',
  error: null,
  currentCategory: null,
};

// Async thunks
export const fetchCategories = createAsyncThunk(
  'categories/fetchAll',
  async () => {
    const response = await axiosInstance.get(`${apiUrl}/category`);
    return response.data;
  }
);

export const createCategory = createAsyncThunk(
  'categories/create',
  async (categoryData: CreateCategoryRequest) => {
    console.log(categoryData);
    const response = await axiosInstance.post(
      `${apiUrl}/category`,
      categoryData
    );
    return response.data;
  }
);

export const updateCategory = createAsyncThunk(
  'categories/update',
  async (categoryData: UpdateCategoryRequest) => {
    const response = await axiosInstance.put(
      `${apiUrl}/category/${categoryData.id}`,
      categoryData
    );
    return response.data;
  }
);

export const deleteCategory = createAsyncThunk(
  'categories/delete',
  async (id: string) => {
    await axiosInstance.delete(`${apiUrl}/category/${id}`);
    return id;
  }
);

export const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    clearCategoryState(state) {
      state.categories = [];
      state.status = 'idle';
      state.error = null;
      state.currentCategory = null;
    },
    setCurrentCategory(state, action) {
      state.currentCategory = action.payload;
    },
    clearCurrentCategory(state) {
      state.currentCategory = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch categories
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.categories = action.payload;
        state.error = null;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to fetch categories';
      })
      // Create category
      .addCase(createCategory.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.categories.push(action.payload);
        state.error = null;
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to create category';
      })
      // Update category
      .addCase(updateCategory.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        const index = state.categories.findIndex(
          (cat) => cat.id === action.payload.id
        );
        if (index !== -1) {
          state.categories[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to update category';
      })
      // Delete category
      .addCase(deleteCategory.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.categories = state.categories.filter(
          (cat) => cat.id !== action.payload
        );
        state.error = null;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to delete category';
      });
  },
});

export const { clearCategoryState, setCurrentCategory, clearCurrentCategory } =
  categorySlice.actions;

// Selectors
export const selectCategories = (state: RootState) =>
  state.categories.categories;
export const selectCategoryStatus = (state: RootState) =>
  state.categories.status;
export const selectCategoryError = (state: RootState) => state.categories.error;
export const selectCurrentCategory = (state: RootState) =>
  state.categories.currentCategory;

export default categorySlice.reducer;
