// src/theme.ts
import { ThemeOptions } from '@mui/material/styles';

// Extending the Theme interface
declare module '@mui/material/styles' {
  interface Palette {
    footer: {
      main: string;
      contrastText: string;
    };
  }
  interface PaletteOptions {
    footer: {
      main: string;
      contrastText: string;
    };
  }
}

const baseThemeOptions: ThemeOptions = {
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'rgba(0, 0, 0, 0.85)',
          backdropFilter: 'blur(8px)',
          borderBottom: '2px solid',
          borderColor: 'rgba(212, 175, 55, 0.3)',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            borderColor: '#D4AF37',
            boxShadow: '0 4px 20px rgba(212, 175, 55, 0.2)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
          transition: 'all 0.3s ease-in-out',
        },
        contained: {
          background: 'linear-gradient(45deg, #D4AF37 30%, #FFD700 90%)',
          '&:hover': {
            background: 'linear-gradient(45deg, #C4A137 30%, #EFC700 90%)',
            boxShadow: '0 4px 20px rgba(212, 175, 55, 0.3)',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          transition: 'all 0.3s ease-in-out',
        },
        filled: {
          background: 'linear-gradient(45deg, #D4AF37 30%, #FFD700 90%)',
          color: '#000000',
          '&:hover': {
            background: 'linear-gradient(45deg, #C4A137 30%, #EFC700 90%)',
          },
        },
        outlined: {
          borderColor: 'rgba(212, 175, 55, 0.5)',
          color: '#D4AF37',
          '&:hover': {
            borderColor: '#D4AF37',
            background: 'rgba(212, 175, 55, 0.08)',
          },
        },
      },
    },
  },
};

export const lightThemeOptions: ThemeOptions = {
  ...baseThemeOptions,
  palette: {
    mode: 'light',
    background: {
      default: '#ffffff',
      paper: '#f8f8f8',
    },
    primary: {
      main: '#D4AF37',
      light: '#FFD700',
      dark: '#C4A137',
      contrastText: '#000000',
    },
    footer: {
      main: '#151515',
      contrastText: '#ffffff',
    },
  },
};

export const darkThemeOptions: ThemeOptions = {
  ...baseThemeOptions,
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: 'rgba(0, 0, 0, 0.85)',
    },
    primary: {
      main: '#D4AF37',
      light: '#FFD700',
      dark: '#C4A137',
      contrastText: '#000000',
    },
    footer: {
      main: '#151515',
      contrastText: '#ffffff',
    },
  },
};
