// src/store/slices/subCategorySlice.ts

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axiosInstance from '../../utilis/axios';
import { apiUrl } from '../../main';
import { SubCategoriesState } from '../../types/states/SubCategoryState.ts';
import {
  CreateSubCategoryRequest,
  UpdateSubCategoryRequest,
} from '../../types/requests/SubCategoryRequests.ts';

const initialState: SubCategoriesState = {
  subCategories: [],
  status: 'idle',
  error: null,
  currentSubCategory: null,
};

// Async thunks
export const fetchSubCategories = createAsyncThunk(
  'subCategories/fetchAll',
  async () => {
    const response = await axiosInstance.get(`${apiUrl}/subcategory`);
    return response.data;
  }
);

export const createSubCategory = createAsyncThunk(
  'subCategories/create',
  async (categoryData: CreateSubCategoryRequest) => {
    console.log(categoryData);
    const response = await axiosInstance.post(
      `${apiUrl}/subcategory`,
      categoryData
    );
    return response.data;
  }
);

export const updateSubCategory = createAsyncThunk(
  'subCategories/update',
  async (categoryData: UpdateSubCategoryRequest) => {
    const response = await axiosInstance.put(
      `${apiUrl}/subcategory/${categoryData.id}`,
      categoryData
    );
    return response.data;
  }
);

export const deleteSubCategory = createAsyncThunk(
  'subCategories/delete',
  async (id: string) => {
    await axiosInstance.delete(`${apiUrl}/subcategory/${id}`);
    return id;
  }
);

export const categorySlice = createSlice({
  name: 'subCategories',
  initialState,
  reducers: {
    clearSubCategoryState(state) {
      state.subCategories = [];
      state.status = 'idle';
      state.error = null;
      state.currentSubCategory = null;
    },
    setCurrentSubCategory(state, action) {
      state.currentSubCategory = action.payload;
    },
    clearCurrentSubCategory(state) {
      state.currentSubCategory = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch categories
      .addCase(fetchSubCategories.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchSubCategories.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.subCategories = action.payload;
        state.error = null;
      })
      .addCase(fetchSubCategories.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to fetch subcategories';
      })
      // Create category
      .addCase(createSubCategory.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(createSubCategory.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.subCategories.push(action.payload);
        state.error = null;
      })
      .addCase(createSubCategory.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to create subcategory';
      })
      // Update category
      .addCase(updateSubCategory.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(updateSubCategory.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        const index = state.subCategories.findIndex(
          (cat) => cat.id === action.payload.id
        );
        if (index !== -1) {
          state.subCategories[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(updateSubCategory.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to update subcategory';
      })
      // Delete category
      .addCase(deleteSubCategory.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(deleteSubCategory.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.subCategories = state.subCategories.filter(
          (cat) => cat.id !== action.payload
        );
        state.error = null;
      })
      .addCase(deleteSubCategory.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to delete subcategory';
      });
  },
});

export const {
  clearSubCategoryState,
  setCurrentSubCategory,
  clearCurrentSubCategory,
} = categorySlice.actions;

// Selectors
export const selectSubCategories = (state: RootState) =>
  state.subCategories.subCategories;
export const selectSubCategoryStatus = (state: RootState) =>
  state.subCategories.status;
export const selectSubCategoryError = (state: RootState) =>
  state.subCategories.error;
export const selectCurrentSubCategory = (state: RootState) =>
  state.subCategories.currentSubCategory;

export default categorySlice.reducer;
