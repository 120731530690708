import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axiosInstance from '../../utilis/axios';
import {
  CreateProductRequest,
  Product,
  UpdateProductRequest,
} from '../../types/product';
import { apiUrl } from '../../main';

interface ProductState {
  products: Product[];
  activeProducts: Product[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  currentProduct: Product | null;
}

const initialState: ProductState = {
  products: [],
  activeProducts: [],
  status: 'idle',
  error: null,
  currentProduct: null,
};

// Async thunks
export const fetchProducts = createAsyncThunk('products/fetchAll', async () => {
  const response = await axiosInstance.get(`${apiUrl}/product`);
  return response.data;
});

export const fetchActiveProducts = createAsyncThunk(
  'products/fetchActive',
  async () => {
    const response = await axiosInstance.get(`${apiUrl}/product/active`);
    return response.data;
  }
);

export const createProduct = createAsyncThunk(
  'products/create',
  async (product: CreateProductRequest) => {
    const response = await axiosInstance.post(`${apiUrl}/product`, product);
    return response.data;
  }
);

export const updateProduct = createAsyncThunk(
  'products/update',
  async (product: UpdateProductRequest) => {
    const response = await axiosInstance.put(
      `${apiUrl}/product/${product.id}`,
      product
    );
    return response.data;
  }
);

export const deleteProduct = createAsyncThunk(
  'products/delete',
  async (id: string) => {
    await axiosInstance.delete(`${apiUrl}/product/${id}`);
    return id;
  }
);

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    clearProductState: (state) => {
      state.products = [];
      state.activeProducts = [];
      state.status = 'idle';
      state.error = null;
      state.currentProduct = null;
    },
    setCurrentProduct: (state, action) => {
      state.currentProduct = action.payload;
    },
    clearCurrentProduct: (state) => {
      state.currentProduct = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all products
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch products';
      })
      // Fetch active products
      .addCase(fetchActiveProducts.fulfilled, (state, action) => {
        state.activeProducts = action.payload;
      })
      // Create product
      .addCase(createProduct.fulfilled, (state, action) => {
        state.products.push(action.payload);
      })
      // Update product
      .addCase(updateProduct.fulfilled, (state, action) => {
        const index = state.products.findIndex(
          (p) => p.id === action.payload.id
        );
        if (index !== -1) {
          state.products[index] = action.payload;
        }
      })
      // Delete product
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.products = state.products.filter(
          (product) => product.id !== action.payload
        );
      });
  },
});

export const { clearProductState, setCurrentProduct, clearCurrentProduct } =
  productSlice.actions;

// Selectors
export const selectProducts = (state: RootState) => state.products.products;
export const selectActiveProducts = (state: RootState) =>
  state.products.activeProducts;
export const selectProductStatus = (state: RootState) => state.products.status;
export const selectProductError = (state: RootState) => state.products.error;
export const selectCurrentProduct = (state: RootState) =>
  state.products.currentProduct;

export default productSlice.reducer;
