// src/store/slices/listingSlice.ts

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axiosInstance from '../../utilis/axios';
import { ListingsState } from '../../types/states/ListingState';
import {
  CreateListingRequest,
  SearchParameters,
  UpdateListingRequest,
} from '../../types/requests/ListingRequests';
import { apiUrl } from '../../main';

const initialState: ListingsState = {
  listings: [],
  status: 'idle',
  error: null,
  currentListing: null,
  searchParams: {
    categoryId: null,
    searchQuery: '',
    // page: 1,
    // pageSize: 10,
    // sortBy: 'createdAt',
    // sortDirection: 'desc',
  },
};

// Async thunks
export const fetchListings = createAsyncThunk('listings/fetchAll', async () => {
  const response = await axiosInstance.get(`${apiUrl}/listing`);
  return response.data;
});

export const searchListings = createAsyncThunk(
  'listings/search',
  async (searchParams: SearchParameters) => {
    const response = await axiosInstance.post(
      `${apiUrl}/listing/search`,
      searchParams
    );
    return response.data;
  }
);

export const fetchListing = createAsyncThunk(
  'listings/fetchOne',
  async (id: string) => {
    const response = await axiosInstance.get(`${apiUrl}/listing/${id}`);
    return response.data;
  }
);

// Fetch listings by company
export const fetchListingsByCompany = createAsyncThunk(
  'listings/fetchByCompany',
  async (id: string) => {
    const response = await axiosInstance.get(`${apiUrl}/listing/company/${id}`);
    return response.data;
  }
);

export const createListing = createAsyncThunk(
  'listings/create',
  async (listingData: CreateListingRequest) => {
    const response = await axiosInstance.post(`${apiUrl}/listing`, listingData);
    return response.data;
  }
);

export const updateListing = createAsyncThunk(
  'listings/update',
  async (listingData: UpdateListingRequest) => {
    const response = await axiosInstance.put(
      `${apiUrl}/listing/${listingData.id}`,
      listingData
    );
    return response.data;
  }
);

export const deleteListing = createAsyncThunk(
  'listings/delete',
  async (id: string) => {
    await axiosInstance.delete(`${apiUrl}/listing/${id}`);
    return id;
  }
);

export const listingSlice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    clearListingState(state) {
      state.listings = [];
      state.status = 'idle';
      state.error = null;
      state.currentListing = null;
      state.searchParams = initialState.searchParams;
    },
    setCurrentListing(state, action) {
      state.currentListing = action.payload;
    },
    clearCurrentListing(state) {
      state.currentListing = null;
    },
    updateSearchParams(state, action) {
      state.searchParams = { ...state.searchParams, ...action.payload };
    },
    resetSearchParams(state) {
      state.searchParams = initialState.searchParams;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch listings
      .addCase(fetchListings.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchListings.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.listings = action.payload;
        state.error = null;
      })
      .addCase(fetchListings.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to fetch listings';
      })
      // Search listings
      .addCase(searchListings.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(searchListings.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.listings = action.payload;
        state.error = null;
      })
      .addCase(searchListings.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to search listings';
      })
      .addCase(fetchListingsByCompany.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.listings = action.payload;
        state.error = null;
      })
      .addCase(fetchListingsByCompany.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to fetch listings';
      })
      .addCase(fetchListingsByCompany.pending, (state) => {
        state.status = 'pending';
      })
      // Fetch single listing
      .addCase(fetchListing.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchListing.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.currentListing = action.payload;
        state.error = null;
      })
      .addCase(fetchListing.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to fetch listing';
      })
      // Create listing
      .addCase(createListing.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(createListing.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.listings.push(action.payload);
        state.error = null;
      })
      .addCase(createListing.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to create listing';
      })
      // Update listing
      .addCase(updateListing.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updateListing.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        const index = state.listings.findIndex(
          (listing) => listing.id === action.payload.id
        );
        if (index !== -1) {
          state.listings[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(updateListing.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to update listing';
      })
      // Delete listing
      .addCase(deleteListing.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(deleteListing.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.listings = state.listings.filter(
          (listing) => listing.id !== action.payload
        );
        state.error = null;
      })
      .addCase(deleteListing.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to delete listing';
      });
  },
});

export const {
  clearListingState,
  setCurrentListing,
  clearCurrentListing,
  updateSearchParams,
  resetSearchParams,
} = listingSlice.actions;

// Selectors
export const selectListings = (state: RootState) => state.listings.listings;
export const selectListingStatus = (state: RootState) => state.listings.status;
export const selectListingError = (state: RootState) => state.listings.error;
export const selectCurrentListing = (state: RootState) =>
  state.listings.currentListing;
export const selectSearchParams = (state: RootState) =>
  state.listings.searchParams;

export default listingSlice.reducer;
