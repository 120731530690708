import { FC, useEffect, useState } from 'react';
import { ProductType } from '../../../../types/product.ts';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchActiveProducts,
  selectProducts,
} from '../../../../store/slices/productSlice.ts';
import { AppDispatch } from '../../../../store/store.ts';

export const CheckoutConfirmation: FC = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get('companyId'); // Gets the 'companyId' query parameter
  const productId = searchParams.get('productId'); // Gets the 'view' query parameter
  const products = useSelector(selectProducts);
  const product = products.find((p) => p.id === productId);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchActiveProducts());
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    const redirect = setTimeout(() => {
      handleManualRedirect();
    }, 10000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirect);
    };
  }, [navigate]);

  const handleManualRedirect = () => {
    navigate(`/company?companyId=${companyId}`);
  };

  return (
    <Card
      sx={{
        background: 'rgba(18, 18, 18, 0.8)',
        backdropFilter: 'blur(10px)',
        borderRadius: 2,
        border: '1px solid rgba(212, 175, 55, 0.1)',
        maxWidth: 600,
        margin: 'auto',
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            mb: 3,
          }}
        >
          <CheckCircleIcon
            sx={{
              color: 'primary.main',
              fontSize: 40,
            }}
          />
          <Typography
            variant="h4"
            sx={{
              color: '#fff',
              fontWeight: 600,
            }}
          >
            Payment Successful!
          </Typography>
        </Box>
        <Typography
          variant="h6"
          sx={{
            color: 'rgba(255, 255, 255, 0.7)',
            mb: 3,
          }}
        >
          {product?.type === ProductType.OneTime
            ? `Thank you for your one-time purchase. Your ${product?.eventClickTokens} event clicks are now available for use.`
            : `Thank you for subscribing. Your yearly subscription is now active with ${product?.eventClickTokens} event clicks available per year.`}
        </Typography>

        <Divider
          sx={{
            my: 3,
            borderColor: 'rgba(212, 175, 55, 0.1)',
          }}
        />

        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h6"
            sx={{
              color: '#fff',
              mb: 2,
            }}
          >
            Purchase Details
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
            }}
          >
            <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              Product
            </Typography>
            <Typography sx={{ color: '#fff', fontWeight: 500 }}>
              {product.name}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
            }}
          >
            <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              Type
            </Typography>
            <Typography sx={{ color: '#fff', fontWeight: 500 }}>
              {product.type === ProductType.OneTime
                ? 'One Time Purchase'
                : 'Yearly Subscription'}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
            }}
          >
            <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              Event Clicks
            </Typography>
            <Typography sx={{ color: '#fff', fontWeight: 500 }}>
              {product.eventClickTokens}x
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              Amount Paid
            </Typography>
            <Typography sx={{ color: 'primary.main', fontWeight: 600 }}>
              ${product.price}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            background: 'rgba(212, 175, 55, 0.05)',
            borderRadius: 1,
            p: 2,
            mt: 3,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            {product.type === ProductType.OneTime
              ? `Your event clicks are ready to use. If you need more clicks in the future, you can make additional purchases at any time. Our support team is available 24/7 to assist you.`
              : `Your subscription benefits are now active and will renew automatically each year. Your event clicks will reset annually. Our support team is available 24/7 to assist you.`}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            mt: 3,
          }}
        >
          <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            You will be redirected in {countdown} seconds...
          </Typography>
          <Button
            variant="contained"
            onClick={handleManualRedirect}
            sx={{
              backgroundColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            }}
          >
            Go to Company Page Now
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
