// src/store/slices/subCategoryServiceSlice.ts

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axiosInstance from '../../utilis/axios';
import { apiUrl } from '../../main';
import { SubCategoryServiceState } from '../../types/states/SubCategoryServiceState.ts';
import {
  CreateSubCategoryServiceRequest,
  UpdateSubCategoryServiceRequest,
} from '../../types/requests/SubCategoryServiceRequests.ts';

const initialState: SubCategoryServiceState = {
  subCategoryServices: [],
  status: 'idle',
  error: null,
  currentSubCategoryService: null,
};

// Async thunks
export const fetchSubCategoryServices = createAsyncThunk(
  'subCategoryServices/fetchAll',
  async () => {
    const response = await axiosInstance.get(`${apiUrl}/subCategoryService`);
    return response.data;
  }
);

export const createSubCategoryService = createAsyncThunk(
  'subCategoryServices/create',
  async (subCategoryData: CreateSubCategoryServiceRequest) => {
    console.log(subCategoryData);
    const response = await axiosInstance.post(
      `${apiUrl}/subCategoryService`,
      subCategoryData
    );
    return response.data;
  }
);

export const updateSubCategoryService = createAsyncThunk(
  'subCategoryServices/update',
  async (subCategoryData: UpdateSubCategoryServiceRequest) => {
    const response = await axiosInstance.put(
      `${apiUrl}/subCategoryService/${subCategoryData.id}`,
      subCategoryData
    );
    return response.data;
  }
);

export const deleteSubCategoryService = createAsyncThunk(
  'subCategoryServices/delete',
  async (id: string) => {
    await axiosInstance.delete(`${apiUrl}/subCategoryService/${id}`);
    return id;
  }
);

export const subCategorySlice = createSlice({
  name: 'subCategoryServices',
  initialState,
  reducers: {
    clearSubCategoryServiceState(state) {
      state.subCategoryServices = [];
      state.status = 'idle';
      state.error = null;
      state.currentSubCategoryService = null;
    },
    setCurrentSubCategoryService(state, action) {
      state.currentSubCategoryService = action.payload;
    },
    clearCurrentSubCategoryService(state) {
      state.currentSubCategoryService = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch subCategories
      .addCase(fetchSubCategoryServices.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchSubCategoryServices.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.subCategoryServices = action.payload;
        state.error = null;
      })
      .addCase(fetchSubCategoryServices.rejected, (state, action) => {
        state.status = 'rejected';
        state.error =
          action.error.message || 'Failed to fetch SubCategory Services';
      })
      // Create subCategory
      .addCase(createSubCategoryService.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(createSubCategoryService.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.subCategoryServices.push(action.payload);
        state.error = null;
      })
      .addCase(createSubCategoryService.rejected, (state, action) => {
        state.status = 'rejected';
        state.error =
          action.error.message || 'Failed to create SubCategory Service';
      })
      // Update subCategory
      .addCase(updateSubCategoryService.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(updateSubCategoryService.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        const index = state.subCategoryServices.findIndex(
          (subCat) => subCat.id === action.payload.id
        );
        if (index !== -1) {
          state.subCategoryServices[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(updateSubCategoryService.rejected, (state, action) => {
        state.status = 'rejected';
        state.error =
          action.error.message || 'Failed to update SubCategory Service';
      })
      // Delete subCategory
      .addCase(deleteSubCategoryService.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(deleteSubCategoryService.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.subCategoryServices = state.subCategoryServices.filter(
          (subCat) => subCat.id !== action.payload
        );
        state.error = null;
      })
      .addCase(deleteSubCategoryService.rejected, (state, action) => {
        state.status = 'rejected';
        state.error =
          action.error.message || 'Failed to delete SubCategory Service';
      });
  },
});

export const {
  clearSubCategoryServiceState,
  setCurrentSubCategoryService,
  clearCurrentSubCategoryService,
} = subCategorySlice.actions;

// Selectors
export const selectSubCategoryServices = (state: RootState) =>
  state.subCategoryServices.subCategoryServices;
export const selectSubCategoryServiceStatus = (state: RootState) =>
  state.subCategoryServices.status;
export const selectSubCategoryServiceError = (state: RootState) =>
  state.subCategoryServices.error;
export const selectCurrentSubCategoryService = (state: RootState) =>
  state.subCategoryServices.currentSubCategoryService;

export default subCategorySlice.reducer;
