// src/store/slices/categoryServiceSlice.ts

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axiosInstance from '../../utilis/axios';
import { apiUrl } from '../../main';
import { CategoryServiceState } from '../../types/states/CategoryServiceState.ts';
import {
  CreateCategoryServiceRequest,
  UpdateCategoryServiceRequest,
} from '../../types/requests/CategoryServiceRequests.ts';

const initialState: CategoryServiceState = {
  categoryServices: [],
  status: 'idle',
  error: null,
  currentCategoryService: null,
};

// Async thunks
export const fetchCategoryServices = createAsyncThunk(
  'categoryServices/fetchAll',
  async () => {
    const response = await axiosInstance.get(`${apiUrl}/categoryService`);
    return response.data;
  }
);

export const createCategoryService = createAsyncThunk(
  'categoryServices/create',
  async (categoryData: CreateCategoryServiceRequest) => {
    console.log(categoryData);
    const response = await axiosInstance.post(
      `${apiUrl}/categoryService`,
      categoryData
    );
    return response.data;
  }
);

export const updateCategoryService = createAsyncThunk(
  'categoryServices/update',
  async (categoryData: UpdateCategoryServiceRequest) => {
    const response = await axiosInstance.put(
      `${apiUrl}/categoryService/${categoryData.id}`,
      categoryData
    );
    return response.data;
  }
);

export const deleteCategoryService = createAsyncThunk(
  'categoryServices/delete',
  async (id: string) => {
    await axiosInstance.delete(`${apiUrl}/categoryService/${id}`);
    return id;
  }
);

export const categorySlice = createSlice({
  name: 'categoryServices',
  initialState,
  reducers: {
    clearCategoryServiceState(state) {
      state.categoryServices = [];
      state.status = 'idle';
      state.error = null;
      state.currentCategoryService = null;
    },
    setCurrentCategoryService(state, action) {
      state.currentCategoryService = action.payload;
    },
    clearCurrentCategoryService(state) {
      state.currentCategoryService = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch categories
      .addCase(fetchCategoryServices.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchCategoryServices.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.categoryServices = action.payload;
        state.error = null;
      })
      .addCase(fetchCategoryServices.rejected, (state, action) => {
        state.status = 'rejected';
        state.error =
          action.error.message || 'Failed to fetch Category Services';
      })
      // Create category
      .addCase(createCategoryService.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(createCategoryService.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.categoryServices.push(action.payload);
        state.error = null;
      })
      .addCase(createCategoryService.rejected, (state, action) => {
        state.status = 'rejected';
        state.error =
          action.error.message || 'Failed to create Category Service';
      })
      // Update category
      .addCase(updateCategoryService.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(updateCategoryService.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        const index = state.categoryServices.findIndex(
          (cat) => cat.id === action.payload.id
        );
        if (index !== -1) {
          state.categoryServices[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(updateCategoryService.rejected, (state, action) => {
        state.status = 'rejected';
        state.error =
          action.error.message || 'Failed to update Category Service';
      })
      // Delete category
      .addCase(deleteCategoryService.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(deleteCategoryService.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.categoryServices = state.categoryServices.filter(
          (cat) => cat.id !== action.payload
        );
        state.error = null;
      })
      .addCase(deleteCategoryService.rejected, (state, action) => {
        state.status = 'rejected';
        state.error =
          action.error.message || 'Failed to delete Category Service';
      });
  },
});

export const {
  clearCategoryServiceState,
  setCurrentCategoryService,
  clearCurrentCategoryService,
} = categorySlice.actions;

// Selectors
export const selectCategoryServices = (state: RootState) =>
  state.categoryServices.categoryServices;
export const selectCategoryServiceStatus = (state: RootState) =>
  state.categoryServices.status;
export const selectCategoryServiceError = (state: RootState) =>
  state.categoryServices.error;
export const selectCurrentCategoryService = (state: RootState) =>
  state.categoryServices.currentCategoryService;

export default categorySlice.reducer;
